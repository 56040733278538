@tailwind base;
@tailwind components;
@tailwind utilities;

/* label, button {
  color: #FF9900;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-left: 40px;
}
input {
  color: #232F3E;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
} */